//= headerDropdownMenu.js
//= searchOpenButton.js
//= registryAccordion.js
//= site.js

window.isMobile = typeof window.orientation !== "undefined";

$(function () {
    //sticky sidebar init
    //= ../../node_modules/sticky-sidebar/dist/sticky-sidebar.min.js

    var $sidebar = $('.sidebar-sticky');
    var $window = $(window);

    if ($sidebar.length && !isMobile) {
        var sidebar = $sidebar[0];

        var stickySidebar = new StickySidebar(sidebar, {
            topSpacing: 60,
            bottomSpacing: 0,
            stickyClass: 'is-affixed'
        });
    }

    //hover 1st link
    var $dropdown = $(".menu-nav .dropdown-toggle");
    if (!isMobile) {
        $dropdown.parent().on('mouseover', function () {
            $(this).addClass("open");
        });
        $dropdown.parent().on('mouseout', function () {
            $(this).removeClass("open");
        });
    } else {
        $dropdown.on('click', function (e) {
            e.preventDefault();
            e.stopPropagation();
            $(this).parent().toggleClass("open");
        });
    }
    //Burger menu
    $('.js-burger-menu').on('click', function () {
        $('.nav-list').slideToggle();
    });

    //close menu
    if (window.isMobile) {
        $(document).mouseup(function (e) {
            var div = $(".nav-list");
            if (!div.is(e.target) &&
                div.has(e.target).length === 0) {
                div.hide();
            }
        });
    }

    $('.main-slider').on('init', function (slick) {
        $(slick.currentTarget).addClass('initialized');
    });

    $('.main-slider').on('destroy', function (slick) {
        $(slick.currentTarget).removeClass('initialized');
        $(slick.currentTarget).addClass('destroyed');
    });

    $('.main-slider').slick({
        infinite: false,
        arrows: true,
        autoplay: true,
        lazyLoad: 'ondemand',
        autoplaySpeed: 5000,
        nextArrow: '<div class="slide-arrow-right"></div>',
        prevArrow: '<div class="slide-arrow-left"></div>',
        responsive: [{
            breakpoint: 767,
            settings: "unslick"
        }]
    });

    $('.main-slider').on('afterChange', function () {
        var currentSlide = $('.main-slider').slick('slickCurrentSlide');
        var slideQuant = $('.slick-slide');
        if (currentSlide == 0) {
            $('.slide-arrow-left').hide();
            $('.slide-arrow-right').show();
        } else if (currentSlide == slideQuant.length - 1) {
            $('.slide-arrow-right').hide();
            $('.slide-arrow-left').show();
        } else {
            $('.slide-arrow-left').show();
            $('.slide-arrow-right').show();
        }
    });

    $(document).ready(function () {
        var currentSlide = $('.main-slider').slick('slickCurrentSlide');
        var slideQuant = $('.slick-slide');
        if (currentSlide == 0) {
            $('.slide-arrow-left').hide();
        } else if (currentSlide == slideQuant.length - 1) {
            $('.slide-arrow-right').hide();
        }
    });

    $(".main-slider").on("lazyLoaded", function(e, slick, image, imageSource) {
        parentSlide = $(image).parent(".main-slider__slide");
        imageSource.src = image.attr("src");
        parentSlide.css("background-image", 'url("' + imageSource + '")').addClass("loaded");
        image.remove();
       });

    $window.on('load', function () {
        $('.lazyload').each(function () {
            var lazy = $(this);
            var src = lazy.attr('data-src');
            $('<img>').attr('src', src).on('load', function () {
                lazy.css('background-image', 'url("' + src + '")');
            });
        });
    });

    $window.resize(function () {
            $('.main-slider').slick('resize');
        });

    $('.js-order-call').on('click', function () {
        $('#order-call').modal();
    });

    $('.js-get-price').on('click', function () {
        $('#get-price').modal();
    });


    function handleTextOnIndexPage() {
        var $text = $('.rostest-center__text');
        var textHeight = $text.height();

        if (textHeight > 70) {
            $text.addClass("collapsed");
            $text.after("<button class='btn-red btn__show-more'>Показать остальное</button>")
        }

        $(document).on("click", ".btn__show-more", function () {
            if ($text.hasClass("collapsed")) {
                $text.removeClass("collapsed");
                $(this).html("Свернуть")
            } else {
                $text.addClass("collapsed");
                $(this).html("Показать остальное");
            }
        })
    }

    function handleCitySelect() {
        $(".contact-info__dropdown").on("change", function () {
            var city = $(this).val();
            window.location.href = "/office/" + city;
        })
    }

    handleTextOnIndexPage();
    handleCitySelect();

    var $sertificate = $("[data-target = '#form-certificate']");
    if ($sertificate.length) {
        $sertificate.on('click', function () {
            var body = $(this).closest('li').find('.title').text();
            $('#form-certificate #form-body').val(body);
        });
    }

    $('.show_more').on('click', function (e) {
        e.preventDefault();
        var $list = $(this).prev('ul');
        var $items = $list.find('.hidden');

        if ($items.length) {
            $items.each(function () {
                $(this).removeClass('hidden');
            });
            $(this).text('Скрыть');
        } else {
            $items = $list.find('li');
            $items.each(function (index, item) {
                if (index >= 4) {
                    $(item).addClass('hidden');
                }
            });
            $(this).text('Показать все');
        }
    });

    // accordion
    $('.accordeon .panel-group .panel .panel-heading')
    .addClass('active')
    .next()
    .slideDown();

    $('.accordeon').prepend( "<h2>Часто задаваемые вопросы</h2>" );

    $('.accordeon .panel-title').each(function () {
        $(this).replaceWith( '<h3 class="panel-title" itemprop="name">' + $(this).html() + '</h3>' );
    });

    $('.accordeon .panel-heading').on('click', function (e) {
        e.preventDefault();
        var $link = $(e.currentTarget);
        $link.addClass('active');
        $('.accordeon .panel-heading').removeClass('active');
        $('.panel-collapse').slideUp();
        var $accordion = $link.next('.panel-collapse');

        if (!$accordion.is(':visible')) {
            $accordion.slideToggle();
            $link.toggleClass('active');
        }
    });

    function deleteComponents(component, width) {
        var $component = $(component);
        if ($window.width() < width) {
            $component.remove();
        }
    }

    deleteComponents('.sidebar-sticky', 992);
    deleteComponents('.slider-wrapper', 992);


  //read more
    var carLmt = 200;
    var readMoreTxt = 'Читать дальше';
    var readLessTxt = 'Скрыть';

    $('.addReadMore').each(function () {
      if ($(this).find('.firstSec').length) return;

      var allstr = $(this).text();
      if (allstr.length > carLmt) {
        var firstSet = allstr.substring(0, carLmt);
        var secdHalf = allstr.substring(carLmt, allstr.length);
        var strtoadd =
          firstSet +
          "<span class='SecSec'>" +
          secdHalf +
          "</span><div class='readMore'  title='Click to Show More'>" +
          readMoreTxt +
          "</div><div class='readLess' title='Click to Show Less'>" +
          readLessTxt +
          '</div>';
        $(this).html(strtoadd);
      }
    });

    $(document).on('click', '.readMore,.readLess', function () {
      $(this)
        .closest('.addReadMore')
        .toggleClass('showlesscontent showmorecontent');
    });

    // modal
    $('.document-button, .document-image').click(function (e) {
      e.preventDefault();
      var $src = $(this).is('a') ? $(this).attr('href') : $(this).attr('src');
      $('.shows').fadeIn();
      $('body').css('overflow-y', 'hidden');
      $('.img-show img').attr('src', $src);
    });

  $('.shows').on('click', function (e) {
    var $container = $('.overlay');
    if (!$container.is(e.target) && !$container.has(e.target).length) {
      $(this).fadeOut();
      $('body').css('overflow-y', 'visible');
    }
  });

  $('.overlay_close').on('click', function () {
    $(this).closest('.shows').fadeOut();
    $('body').css('overflow-y', 'visible');
  });

  $('.article__image.product-image > img').click(function () {
    var $src = $(this).attr('src');
    $('.shows').fadeIn();
    $('body').css('overflow-y', 'hidden');
    $('.img-show img').attr('src', $src);
  });

   // rating comment

   $('.question-comment').each(function () {
    var starCount = $(this).find('.rating-count').html();
    $(this).find('.rating-result span').slice(0, starCount).addClass('active');
  });

  // avg rating

  if ($('.comments-avg .rating-count').length) {
    var starCountAvg = $('.comments-avg .rating-count').html();
    var toInteger = starCountAvg.toString()[0];
    var allStars = $('.comments-avg .rating-result span:not(.back-star)')
      .slice(0, toInteger)
      .addClass('active');

    if (starCountAvg % 1 !== 0) {
      var numberAfterDot = starCountAvg.split('.')[1].slice(0, 2);
      var text = numberAfterDot.toString();
      var index = text.indexOf('.');
      var countAfterDot = text.length - index - 1;

      countAfterDot === 1
        ? (numberAfterDot += '0')
        : numberAfterDot &&
          $('.comments-avg .rating-count').html(
            parseFloat(starCountAvg).toFixed(2)
          );

      allStars
        .last()
        .next()
        .addClass('active')
        .find('.back-star')
        .css({
          left: numberAfterDot + '%',
        });
    }
  }

});

$('a.link-table-of-contents').click(function () {
    var target = $(this).attr('href');
    target = document.getElementById(target.substring(1));
    $('html, body').animate({ scrollTop: $(target).offset().top - 70 }, 600);
});

var anchor = location.hash;
var anchorEl = document.getElementById(anchor.substring(1));
if(anchorEl) {
    var target = $(anchorEl);
    $('html, body').animate({ scrollTop: $(target).offset().top - 70 }, 600);
}